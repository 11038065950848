import React from 'react';
import './ReportDetailsSummary.scss';
import { ReportDetailsSummaryProps } from './ReportDetailsSummary.types';
import Grid from '@mui/material/Grid';

export const ReportDetailsSummary: React.FC<ReportDetailsSummaryProps> = ({ rows }) => {
  return (
    <div className='report-details-summary'>
      {rows
        .filter(({ value }) => value)
        .map(({ label, value }) => (
          <Grid container rowSpacing={2} key={label}>
            <Grid item xs={4} className='label'>
              {label}:
            </Grid>
            <Grid item xs={8} className='value'>
              {value}
            </Grid>
          </Grid>
        ))}
    </div>
  );
};
