import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ADMIN_PANEL_ENDPOINT,
  ADMIN_PANEL_METADATA_ENDPOINT,
  ADMIN_PANEL_USER_ENDPOINT,
  ADMIN_PANEL_USER_GROUPS_ENDPOINT,
  ADMIN_PANEL_USERS_ENDPOINT,
  ADMIN_PANEL_UPLOAD_METADATA_CSV_ENDPOINT,
} from '../../services/api.service.config';
import { appendAuthorizationHeader } from '../../components/Auth/auth.helpers';
import {
  AdminPanelCreatedUser,
  AdminPanelMetadata,
  AdminPanelUser,
  UpdateUserGroups,
  UploadMetadataCsv,
} from './adminPanel.types';

enum ADMIN_PANEL_TAGS {
  ADMIN_PANEL_USERS_LIST = 'ADMIN_PANEL_USERS_LIST',
}

export const adminPanelApi = createApi({
  reducerPath: 'adminPanelApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ADMIN_PANEL_ENDPOINT,
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [ADMIN_PANEL_TAGS.ADMIN_PANEL_USERS_LIST],
  endpoints: (builder) => ({
    getMetadata: builder.query<AdminPanelMetadata, void>({
      query: () => ({
        url: ADMIN_PANEL_METADATA_ENDPOINT,
      }),
    }),
    createUser: builder.mutation<void, AdminPanelCreatedUser>({
      query: (body) => ({
        url: ADMIN_PANEL_USER_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),
    updateUserGroups: builder.mutation<void, UpdateUserGroups>({
      query: (body) => ({
        url: ADMIN_PANEL_USER_GROUPS_ENDPOINT,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : [ADMIN_PANEL_TAGS.ADMIN_PANEL_USERS_LIST]),
    }),
    getUsers: builder.query<AdminPanelUser[], void>({
      query: () => ({
        url: ADMIN_PANEL_USERS_ENDPOINT,
      }),
      providesTags: [ADMIN_PANEL_TAGS.ADMIN_PANEL_USERS_LIST],
    }),
    updateUser: builder.mutation<void, AdminPanelUser>({
      query: (body) => ({
        url: ADMIN_PANEL_USER_ENDPOINT,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : [ADMIN_PANEL_TAGS.ADMIN_PANEL_USERS_LIST]),
    }),
    uploadMetadataCsv: builder.mutation<void, UploadMetadataCsv>({
      query: (body) => ({
        url: ADMIN_PANEL_UPLOAD_METADATA_CSV_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetMetadataQuery,
  useCreateUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
  useUpdateUserGroupsMutation,
  useUploadMetadataCsvMutation,
} = adminPanelApi;
