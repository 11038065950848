import React from 'react';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import { useAppDispatch } from '../../../store/hooks';
import { isClauseResponse } from '../../../store/files/documentsAndClauses/list.helpers';
import { openValidateModal } from '../../../store/ui/ui.slice';
import MenuItem from '@mui/material/MenuItem';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useUserAccessContext } from '../../../contexts/UserAccess';

export const DocumentContextMenuValidate = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const { hasValidateModalAccess } = useUserAccessContext();
  const dispatch = useAppDispatch();

  const handleMenuItemClick = () => {
    if (document && isClauseResponse(document)) {
      dispatch(openValidateModal(document));
    }
    onClose();
  };

  if (!hasValidateModalAccess) return null;
  return (
    <MenuItem disabled={disabled} onClick={handleMenuItemClick}>
      <TaskAltIcon />
      Validate
    </MenuItem>
  );
};
