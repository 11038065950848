import { UploadMetadataCSVMessages } from './UploadMetadataCSV.messages';

export const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(UploadMetadataCSVMessages.FILE_READ_ERROR);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result as string);
    reader.onerror = () => reject(UploadMetadataCSVMessages.FILE_READ_ERROR);

    try {
      reader.readAsText(file);
    } catch {
      reject(UploadMetadataCSVMessages.FILE_READ_ERROR);
    }
  });
};
