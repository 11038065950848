import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import './ReportItemModal.scss';
import { MarsModal } from '../../StaticComponents/Modals/MarsModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { closeReportedReasonModal } from '../../../store/ui/ui.slice';
import {
  useGetClauseDictionariesQuery,
  useGetDictionariesQuery,
} from '../../../store/files/upload/list.service';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import {
  isClauseResponse,
  isDocumentResponse,
} from '../../../store/files/documentsAndClauses/list.helpers';
import { CustomTextField, requiredRule } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import {
  MAX_REPORTING_REASON_COMMENT_LENGTH,
  REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION,
  REPORTING_FORM_LABELS,
  REPORTING_SUMMARY_LABELS,
} from '../../../config/config';
import {
  documentsListApi,
  useReportDocumentMutation,
} from '../../../store/files/documents/documents.list.service';
import {
  clausesListApi,
  useReportClauseMutation,
} from '../../../store/files/clauses/clauses.list.service';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { ErrorMessages } from '../../../services/errors.service.types';
import { useSnackbar } from 'notistack';
import { ReportFormData } from './ReportItemModal.types';
import { DOCUMENTS_LIST_API_TAGS } from '../../../store/files/documents/documents.list.types';
import { CLAUSES_LIST_API_TAGS } from '../../../store/files/clauses/clauses.list.types';
import { timeoutPromise } from '../../../helpers/app.helpers';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { DocumentInfoHeader } from '../../StaticComponents/DocumentInfoHeader/DocumentInfoHeader';
import { ClauseItem } from '../../Clauses/ClauseItem/ClauseItem';
import { ReportDetailsSummary } from './ReportDetailsSummary';
import { getDictionaryLabelByValue } from '../../../store/files/upload/list.helpers';
import { formatItemType, handleCloseItemViewModal } from '../../../store/ui/ui.helpers';
import { ITEM_TYPE } from '../../../store/ui/ui.types';

export const ReportedItemModal = () => {
  const dispatch = useAppDispatch();
  const item = useAppSelector(uiSelectors.selectReportedReasonModal);
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, reset, getValues } = useForm<ReportFormData>({
    mode: 'onChange',
  });

  const { enqueueSnackbar } = useSnackbar();

  const useDictionariesQuery = isDocumentResponse(item)
    ? useGetDictionariesQuery
    : useGetClauseDictionariesQuery;
  const { data: dictionaries } = useDictionariesQuery(
    { AccessMode: AccessMode.READ },
    { skip: !item }
  );
  const [reportDocument] = useReportDocumentMutation();
  const [reportClause] = useReportClauseMutation();

  const invalidateCache = isDocumentResponse(item)
    ? documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST])
    : clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST]);

  const handleSubmitForm = async (data?: ReportFormData) => {
    setIsLoading(true);
    try {
      if (isDocumentResponse(item)) {
        await reportDocument({
          DocumentId: item.DocumentId,
          ReportedReason: data!.reportedReason,
          ReportedComment: data!.comment,
        }).unwrap();
      } else {
        await reportClause({
          DocumentId: item!.ClauseId,
          ReportedReason: data!.reportedReason,
          ReportedComment: data!.comment,
        }).unwrap();
      }
      await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
      dispatch(invalidateCache);
      setStep(1);
    } catch (error) {
      enqueueSnackbar(ErrorMessages.FileReportError, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    dispatch(closeReportedReasonModal());
    setStep(0);
    handleCloseItemViewModal(dispatch, item);
  };

  const itemTypeName = isDocumentResponse(item)
    ? formatItemType(ITEM_TYPE.DOCUMENT)
    : formatItemType(ITEM_TYPE.CLAUSE);

  const capitalizedItemTypeName = `${itemTypeName.charAt(1).toUpperCase()}${itemTypeName.slice(2)}`;

  return (
    <MarsModal
      className='report-reason-modal'
      open={!!item}
      onConfirm={step === 0 ? handleSubmit(handleSubmitForm) : handleClose}
      onClose={handleClose}
      buttonLabel={step === 0 ? 'Submit' : 'Close'}
      loading={isLoading}
    >
      <div className='bookmarks-modal-title'>Report {itemTypeName}</div>

      {step === 0 && (
        <>
          {isDocumentResponse(item) && <DocumentInfoHeader document={item} />}
          {isClauseResponse(item) && <ClauseItem clause={item} searchQuery='' />}
          <div>
            The report will be sent to moderators. After submitting a report, the
            {itemTypeName} will be temporarily hidden from GWR. Choose the reason why You're
            reporting this file.
          </div>
          <form className='report-reason-form'>
            <div className='reason-dropdown'>
              <Controller
                name='reportedReason'
                control={control}
                rules={requiredRule(true, true)}
                defaultValue=''
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    data-test-id={TEST_ID.REPORTING_FILES_MODAL.REPORTED_REASON_INPUT}
                    {...field}
                    helperWarning={error}
                    select
                    label={REPORTING_FORM_LABELS.REASON}
                    values={dictionaries?.ReportedReason.values}
                  />
                )}
              />
            </div>
            <div className='comment-wording'>
              <Controller
                name='comment'
                control={control}
                defaultValue=''
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    data-test-id={TEST_ID.REPORTING_FILES_MODAL.REPORTED_COMMENT_INPUT}
                    inputProps={{ maxLength: MAX_REPORTING_REASON_COMMENT_LENGTH }}
                    {...field}
                    multiline
                    helperWarning={error}
                    label=''
                    hasCounter
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={REPORTING_FORM_LABELS.COMMENT}
                  />
                )}
              />
            </div>
          </form>
        </>
      )}
      {step === 1 && (
        <div className='success-message'>
          <TaskAltIcon className='success-icon' />
          <div className='success-wording'>
            {capitalizedItemTypeName} was successfully reported!
          </div>
          {isDocumentResponse(item) && <DocumentInfoHeader document={item} />}
          {isClauseResponse(item) && <ClauseItem clause={item} searchQuery='' />}
          <ReportDetailsSummary
            rows={[
              {
                label: REPORTING_SUMMARY_LABELS.REASON,
                value: getDictionaryLabelByValue(
                  dictionaries!.ReportedReason,
                  getValues('reportedReason')
                ),
              },
              {
                label: REPORTING_SUMMARY_LABELS.COMMENT,
                value: getValues('comment'),
              },
            ]}
          />
        </div>
      )}
    </MarsModal>
  );
};
