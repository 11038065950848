import {
  DocumentMetadataUpdateRequest,
  ErrorResponse,
  ValidationError,
  ValidationErrorCode,
  ValidationErrorResponse,
} from './documents.list.types';
import {
  DocumentUpdateDescriptions,
  DocumentUpdateStatuses,
} from '../../../components/SpreadSheet/DocumentsGrid/DocumentsGrid.types';
import { ErrorMessages } from '../../../services/errors.service.types';
import { mapStatusToIds } from './documents.update.success.helpers';
import { getDocumentIds } from '../../../components/SpreadSheet/DocumentsGrid/AchDocumentsGrid.helpers';
import { AdminPanelErrorDescriptions } from '../../adminPanel/adminPanel.types';

const ERROR_MESSAGE_SEPARATOR = ', ';
export const ERROR_DOCUMENT_SEPARATOR = '; ';
const NO_KEY = 'Other';

const concatErrors = (prev: string, error: string) =>
  (prev ? prev + ERROR_MESSAGE_SEPARATOR : '') + error;

const getErrorDescriptionByCode = ({ errorCode, errorDetails }: ValidationError) => {
  const paramName = errorDetails?.paramName || '';
  switch (errorCode) {
    case ValidationErrorCode.DocumentNotExist:
      return DocumentUpdateDescriptions.DocumentNotExist;
    case ValidationErrorCode.MissingMandatoryFields:
      const missingFields = errorDetails?.missingFields;
      const mapped = Array.isArray(missingFields)
        ? missingFields?.map((field) => `"${field}"`).join(', ')
        : missingFields;
      const fields = mapped ? `: ${mapped}` : '';
      return DocumentUpdateDescriptions.MissingMandatoryFields.replace('{fields}', fields);
    case ValidationErrorCode.InvalidValue:
      return DocumentUpdateDescriptions.InvalidValue.replace('{field}', paramName);
    case ValidationErrorCode.InvalidParamName:
      return DocumentUpdateDescriptions.InvalidParamName;
    case ValidationErrorCode.InvalidDateOrder:
      return DocumentUpdateDescriptions.InvalidDateOrder;
    case ValidationErrorCode.InvalidFormat:
      const formattedDetails = errorDetails?.paramName
        ? paramName
        : `row ${errorDetails?.row_number}, value "${errorDetails?.date_field}" (expected format: ${errorDetails?.expected})`;
      return DocumentUpdateDescriptions.InvalidFormat.replace('{field}', formattedDetails);
    case ValidationErrorCode.InvalidColumn:
      const providedColumn = errorDetails?.providedColumn || '';
      return DocumentUpdateDescriptions.InvalidColumn.replace('{field}', providedColumn);
    case ValidationErrorCode.InvalidFileNameFormat:
      return DocumentUpdateDescriptions.InvalidFileNameFormat;
    case ValidationErrorCode.IncorrectDocumentStatus:
      return DocumentUpdateDescriptions.IncorrectDocumentStatus;
    case ValidationErrorCode.MissingFields:
      return DocumentUpdateDescriptions.MissingFields;
    case ValidationErrorCode.IncorrectHeaders:
      return DocumentUpdateDescriptions.IncorrectHeaders;
    case ValidationErrorCode.FileEmptyOrCorrupted:
      return DocumentUpdateDescriptions.FileEmptyOrCorrupted;
    case ValidationErrorCode.NoPermissionsToParamValue:
      return DocumentUpdateDescriptions.NoPermissions.replace('{field}', paramName);
    case ValidationErrorCode.NoWritePermissions:
      return DocumentUpdateDescriptions.NoWritePermissions;
    case ValidationErrorCode.NoPermissionToModifyFiles:
      return DocumentUpdateDescriptions.NoPermissionToModifyFiles;
    case ValidationErrorCode.UnknownError:
      return DocumentUpdateDescriptions.UnknownError;
    case ValidationErrorCode.NotAllowedSizeOfFile:
      return DocumentUpdateDescriptions.NotAllowedSizeOfFile;
    case ValidationErrorCode.InvalidTypeOfFile:
      return DocumentUpdateDescriptions.InvalidTypeOfFile;
    case ValidationErrorCode.ProtectedFileByPassword:
      return DocumentUpdateDescriptions.ProtectedFileByPassword;
    case ValidationErrorCode.UnprocessableEntity:
      return DocumentUpdateDescriptions.UnprocessableEntity;
    case ValidationErrorCode.NumberOfFilesNotAllowed:
      return DocumentUpdateDescriptions.NumberOfFilesNotAllowed;
    case ValidationErrorCode.DuplicateNameDetected:
      return DocumentUpdateDescriptions.DuplicateNameDetected;
    case ValidationErrorCode.DuplicateNameDetectedReport:
      return DocumentUpdateDescriptions.DuplicateNameDetected;
    case ValidationErrorCode.UnknownErrorInMSS:
      return DocumentUpdateDescriptions.UnknownErrorInMSS;
    case ValidationErrorCode.InvalidScanningReport:
      return DocumentUpdateDescriptions.InvalidScanningReport;
    case ValidationErrorCode.DocumentDuplicate:
      return DocumentUpdateDescriptions.DocumentDuplicate;
    case ValidationErrorCode.ClauseDuplicate:
      return DocumentUpdateDescriptions.ClauseDuplicate;
    case ValidationErrorCode.ClauseDuplicateIncompatibleLanguage:
      return DocumentUpdateDescriptions.ClauseDuplicateIncompatibleLanguage;
    case ValidationErrorCode.ClauseDuplicateMetadataExtended:
      return DocumentUpdateDescriptions.ClauseDuplicateMetadataExtended;
    case ValidationErrorCode.ClauseAutomatedExtractMissingTag:
      return DocumentUpdateDescriptions.ClauseAutomatedExtractMissingTag;
    case ValidationErrorCode.UnknownErrorACH:
      return DocumentUpdateDescriptions.UnknownErrorACH;
    case ValidationErrorCode.UserAlreadyExists:
      return AdminPanelErrorDescriptions.UserAlreadyExists;
    case ValidationErrorCode.UserNotFound:
      return AdminPanelErrorDescriptions.UserNotFound;
    case ValidationErrorCode.UserGroupNotFound:
      return AdminPanelErrorDescriptions.UserGroupNotFound;
    case ValidationErrorCode.ProviderMismatch:
      return DocumentUpdateDescriptions.ProviderMismatch;
    case ValidationErrorCode.NoClauseEditPermissions:
      return DocumentUpdateDescriptions.NoClauseEditPermissions;
    case ValidationErrorCode.NoPermissionsToDeleteAllMetadata:
      return DocumentUpdateDescriptions.NoPermissionsToDeleteAllMetadata;
    case ValidationErrorCode.MetadataMismatch:
      return DocumentUpdateDescriptions.MetadataMismatch;
    case ValidationErrorCode.ResubmittedContentNotPresentInClause:
      return DocumentUpdateDescriptions.ResubmittedContentNotPresentInClause;
    case ValidationErrorCode.TagNameAlreadyExists:
      return DocumentUpdateDescriptions.TagNameAlreadyExists;
    case ValidationErrorCode.TagNameDoesNotExist:
      return DocumentUpdateDescriptions.TagNameDoesNotExist;
    case ValidationErrorCode.ClauseContentChanged:
      return DocumentUpdateDescriptions.ClauseContentChanged;
    case ValidationErrorCode.ProductionDuplicateDraftClause:
      return DocumentUpdateDescriptions.ProductionDuplicateDraftClause;
    default:
      return `${DocumentUpdateDescriptions.OtherError} (${errorCode})`;
  }
};

export const mapValidationErrorsToRecord = (errors: ValidationError[] = [], byFileName = false) => {
  const field = byFileName ? 'fileName' : 'documentId';
  return (
    errors.reduce((pv, error) => {
      const key = error.errorDetails?.[field] || NO_KEY;
      return {
        ...pv,
        [key]: concatErrors(pv[key], getErrorDescriptionByCode(error)),
      };
    }, {} as DocumentUpdateStatuses) || {}
  );
};

export const mapErrorResponseToUpdateStatuses = (
  response: ErrorResponse<ValidationErrorResponse>,
  documents: DocumentMetadataUpdateRequest
): DocumentUpdateStatuses => {
  let mappedErrors = mapStatusToIds(getDocumentIds(documents), ErrorMessages.MetadataUpdateError);
  if (response.data?.errors?.length) {
    mappedErrors = { ...mappedErrors, ...mapValidationErrorsToRecord(response.data.errors) };
  }
  return mappedErrors;
};

export const mapErrorResponseToErrorMessage = (error: ValidationError[]) => {
  return Object.entries(mapValidationErrorsToRecord(error, true))
    .map(([key, value]) => `${key}: ${value}`)
    .join(ERROR_DOCUMENT_SEPARATOR);
};

export const mapErrorResponseToErrorMessageDetails = (error: ValidationError[]) => {
  return mapValidationErrorsToRecord(error, true).Other;
};

export const getDuplicatedClauseId = (errorDetails: ValidationError['errorDetails']) => {
  return (
    errorDetails?.extendedClauseId ||
    errorDetails?.duplicateClauseId ||
    errorDetails?.duplicatedIds?.[0]
  );
};
