export const TEST_ID = {
  SEARCH_QUERY: {
    FIELD: 'search-query__field',
    FIELD_CLEAN: 'search-query__field-clean',
    FIELD_SUBMIT: 'search-query__field-submit',
  },
  UPLOAD_FILES: {
    DRAG_AND_DROP: 'drag-and-drop__upload-document',
    BULK_METADATA_EDIT: 'bulk-metadata__button',
    DOCUMENT_LIST_VIEW: 'document-list__all_documents_view',
    DELETE_UPLOADED_DOCUMENT: 'document-list__delete_uploaded_documents',
    DELETE_UPLOADED_CONFIRM: 'document-list__confirm_deleting_modal',
  },
  BULK_METADATA: {
    FILE_NAME: 'bulk-metadata-form__file_name',
    ENTITY: 'bulk-metadata-form__entity',
    DOCUMENT_VERSION: 'bulk-metadata-form__document_version',
    PARENT_DOCUMENTS: 'bulk-metadata-form__parent_docuemnts',
    RELATED_VERSIONS: 'bulk-metadata-form__related_versions',
    LATEST_VERSION: 'bulk-metadata-form__latest_version',
    DOCUMENT_TYPE: 'bulk-metadata-form__document_type',
    LANGUAGE: 'bulk-metadata-form__language',
    LOB: 'bulk-metadata-form__lob',
    COUNTRY: 'bulk-metadata-form__country',
    CLASSIFICATION: 'bulk-metadata-form__classification',
    VALID_FROM: 'bulk-metadata-form__valid_from',
    VALID_TO: 'bulk-metadata-form__valid_to',
    SAVE_BUTTON: 'bulk-metadata-form__save_button',
    DOCUMENT_NAME: 'bulk-metadata-form__document_name',
    LOP: 'bulk-metadata-form__lop',
    BUSINESS_TYPE: 'bulk-metadata-form__business_type',
    ACTIVE: 'bulk-metadata-form__active',
    EXPIRE_AT: 'bulk-metadata-form__expire_at',
    REMARKS: 'bulk-metadata-form__remarks',
    PRODUCT_TYPE: 'bulk-metadata-form__product_type',
    INSURED_INDUSTRY: 'bulk-metadata-form__insurer_industry',
  },
  DOC_VIEW: {
    MINIMIZE_DOC: 'minimize-doc',
    CLOSE_DOC: 'close-doc',
    TOGGLE_COMPARE: 'toggle-compare',
  },
  DOCUMENT_VIEW_MODAL: {
    DETAILS_TAB: 'details-tab',
    CLAUSES_TAB: 'clauses-tab',
    VERSIONS_TAB: 'versions-tab',
    COMMENTS_TAB: 'comments-tab',
  },
  CLAUSE_VIEW_MODAL: {
    INFO_TAB: 'info-tab',
    DOCUMENTS_TAB: 'documents-tab',
    SIMILAR_TAB: 'similar-tab',
    COMMENTS_TAB: 'comments-tab',
  },
  DOC_BAR: {
    OPEN_DOC: 'doc-bar-open-doc',
    CLOSE_DOC: 'doc-bar-close-doc',
    COMPARE: 'doc-bar-compare',
    MENU: 'doc-bar-menu',
    MENU_CLEAR_COMPARE: 'doc-bar-clear-compare',
    MENU_CLEAR_DOCS: 'doc-bar-clear-docs',
  },
  DOCUMENTS_PAGE: {
    DOCUMENTS_PAGE_BUTTON_LINK: 'doc-page__navbar-button',
    DOCUMENTS_PAGE_DOCUMENTS_LIST: 'doc-page__documents-list',
    DOCUMENTS_PAGE_COLUMNS_CHOICE: 'doc-page__columns_choice',
    DOCUMENTS_PAGE_DOCUMENTS_COUNTER: 'doc-page__documents-counter',
    DOCUMENTS_PAGE_SAVE_SEARCH_BUTTON: 'doc-page__save-search-button',
    DOCUMENTS_PAGE_SAVE_SEARCH_INPUT: 'doc-page__save-search-input',
    DOCUMENTS_PAGE_SAVE_SEARCH_MODAL: 'doc-page__save-search-modal',
    DOCUMENTS_PAGE_COLUMNS_LIST: 'doc-page__save-columns-list',
    DOCUMENTS_PAGE_PAGINATION: 'doc-page__save-pagination',
  },
  CLAUSES_PAGE: {
    CLAUSES_PAGE_BUTTON_LINK: 'clauses-page__navbar-button',
  },
  BOOKMARKS_PAGE: {
    BOOKMARKS_PAGE_BUTTON_LINK: 'bookmarks-page__navbar-button',
  },
  ADMIN_PANEL_PAGE: {
    ADMIN_PANEL_PAGE_BUTTON_LINK: 'admin_panel__navbar-button',
    ADMIN_PANEL_EDIT_USER_GROUPS_MODAL: 'admin_panel__edit-user-groups-form',
    ADMIN_PANEL_BULK_EDIT_USER_GROUPS_MODAL: 'admin_panel__bulk-edit-user-groups-form',
  },
  REPORTS_PAGE: {
    REPORTS_PAGE_BUTTON_LINK: 'reports__navbar-button',
  },
  SANDBOX_PAGE: {
    SANDBOX_PAGE_BUTTON_LINK: 'sandbox__navbar-button',
  },
  SAVED_SEARCHES: {
    SETTINGS_PAGE_BUTTON_LINK: 'settings__navbar-button',
    SAVED_SEARCHES_LIST: 'settings__saved-searches-list',
    SAVED_SEARCHES_EDIT: 'settings__saved-searches-edit',
    SAVED_SEARCHES_EDIT_MODAL: 'settings__saved-searches-edit-modal',
    SAVED_SEARCHES_EDIT_NAME: 'settings__saved-searches-edit-name',
    SAVED_SEARCHES_EDIT_QUERY: 'settings__saved-searches-edit-query',
    SAVED_SEARCHES_EDIT_FILTERS: 'settings__saved-searches-edit-filters',
    SAVED_SEARCHES_BULK_DELETE: 'settings__saved-searches-bulk-delete',
    SAVED_SEARCHES_INFO_BUTTON: 'settings__saved-searches-info-button',
    SAVED_SEARCHES_DUPLICATE_INFO: 'settings__saved-searches-duplicate-info',
    SAVED_SEARCHES_INFO_POPPER: 'settings__saved-searches-info-popper',
  },
  REPORTING_FILES_MODAL: {
    REPORTED_REASON_INPUT: 'report_file_modal__reported_reason',
    REPORTED_COMMENT_INPUT: 'report_file_modal__reported_comment',
  },
  VALIDATE_MODAL: {
    STATE_INPUT: 'validate_modal__state',
    STATE_COMMENT_INPUT: 'validate_modal__state_comment',
    STATUS_INPUT: 'validate_modal__status',
    STATUS_COMMENT_INPUT: 'validate_modal__status_comment',
  },
  SEARCH_OPTIONS: {
    SEARCH_OPTIONS_LIST: 'search-options__searches-list',
    SEARCH_EXACT: 'search-options__exact-search',
    SEARCH_BROAD: 'search-options__broad-search',
    SEARCH_PHRASE: 'search-options__phrase-search',
  },
};
