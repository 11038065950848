export enum Approver {
  COMPLIANCE = 'compliance',
  LEGAL = 'legal',
}

export interface UserGroup {
  Approver: Approver | '';
  Classification: string[];
  Draft: boolean;
  Entity: string[];
  GroupName: string;
  Mode: string;
  Owner: string;
  Provider: string;
  ShowReported: boolean;
  Type: string;
}

export interface AuthorizationResponse {
  Entity: string;
  Email: string;
  UserGroups: string[];
  UserGroupsProperties: UserGroup[] | string[];
  Active: boolean;
  UserId: string;
  Name: string;
  FamilyName: string;
  CreatedAt: string;
  UpdatedAt: string;
}
