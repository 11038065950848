import React, { Dispatch, useRef, useState } from 'react';
import { MarsModal } from '../../StaticComponents/Modals/MarsModal';
import './UploadMetadataCSV.scss';
import { useUploadMetadataCsvMutation } from '../../../store/adminPanel/adminPanel.service';
import { showModal } from '../../../store/ui/ui.slice';
import { handleApiError } from '../../../store/error.helpers';
import { useAppDispatch } from '../../../store/hooks';
import { Messages } from '../../../services/messages.service.types';
import { useSnackbar } from 'notistack';
import { readFileAsText } from './UploadMetadataCSV.helpers';
import { UploadMetadataCSVMessages } from './UploadMetadataCSV.messages';

export const UploadMetadataCSV: React.FC<{ onClose: Dispatch<boolean> }> = ({ onClose }) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadMetadataCsv] = useUploadMetadataCsvMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onUploadMetadataFile = async (event: React.FormEvent<HTMLInputElement>) => {
    const file = event?.currentTarget?.files?.[0];
    if (file) {
      setIsUploading(true);

      try {
        const csvString = await readFileAsText(file);
        await uploadMetadataCsv({ Csv: csvString }).unwrap();
        enqueueSnackbar(Messages.CSVMetadataUploadSuccess);
        onClose(false);
      } catch (e) {
        onClose(false);
        dispatch(
          showModal({
            title: UploadMetadataCSVMessages.ERROR_MODAL_TITLE,
            reason:
              e === UploadMetadataCSVMessages.FILE_READ_ERROR
                ? UploadMetadataCSVMessages.FILE_READ_ERROR
                : handleApiError(e as Error, true),
          })
        );
      } finally {
        setIsUploading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    }
  };

  return (
    <MarsModal
      open={true}
      onClose={() => onClose(false)}
      title={UploadMetadataCSVMessages.MODAL_TITLE}
      buttonLabel={UploadMetadataCSVMessages.BUTTON_LABEL}
      showCancelButton={true}
      loading={isUploading}
      onConfirm={() => {
        fileInputRef.current?.click();
      }}
      className='upload-csv-metadata-modal'
    >
      <div className='upload-metadata-description'>
        Please note that metadata CSV uploads are limited to once per day. <br /> The data will be
        processed overnight.
      </div>
      <input
        ref={fileInputRef}
        type='file'
        style={{ display: 'none' }}
        accept='text/csv, .csv'
        onChange={onUploadMetadataFile}
      />
    </MarsModal>
  );
};
