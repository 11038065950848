import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { MetadataForm } from '../MetadataForm/MetadataForm';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { documentsSelectors } from '../../../store/files/documents/documents.selectors';
import { closeMetadataEditModal } from '../../../store/files/documents/documents.slice';
import './AchMetadataEditModal.scss';
import { useUpdateDocumentsMetadataMutation } from '../../../store/files/documents/documents.list.service';
import { DocumentMetadata } from '../MetadataForm/MetadataForm.types';
import { Messages } from '../../../services/messages.service.types';
import { LanguageToggleSwitch } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch';
import { DocumentInfoHeader } from '../../StaticComponents/DocumentInfoHeader/DocumentInfoHeader';
import { MinimizeItem } from '../../StaticComponents/Modals/MinimizeItem/MinimizeItem';
import { updateStatuses } from '../../../store/ui/ui.slice';
import { MY_FILES_BLOCKED_COLUMNS } from '../../../config/config';
import { useLanguageToggleSwitch } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.hook';
import { AchDocumentViewer } from './AchDocumentViewer';
import { mapErrorResponseToUpdateStatuses } from '../../../store/files/documents/documents.update.error.helpers';
import {
  isAchResponseWithErrors,
  mapAchErrorResponseToErrorMessage,
  mapSuccessResponseToUpdateStatuses,
} from '../../../store/files/documents/documents.update.success.helpers';
import { mapToDocumentMetadataUpdateRequestDto } from '../../SpreadSheet/DocumentsGrid/AchDocumentsGrid.helpers';
import { handleApiError } from '../../../store/error.helpers';
import { useLoadingWithTimeout } from '../../../store/files/documents/documents.hooks';
import { DocumentViewModalProps } from '../../Documents/Documents.types';
import { WarningModal } from '../../StaticComponents/Modals/WarningModal/WarningModal';
import { useCheckIfDocumentIsParent } from '../../Documents/DocumentContextMenu/useCheckIfDocumentIsParent';
import { getLastNotEmptyQuery } from '../../Documents/DocumentViewModal/DocumentViewModal.helpers';

const PARENT_RELATION_WARNING =
  'There is at least one document related to a given file in the GWR. If You will change Line of Business, Line of Product or a Document Type all relations to this file might be lost.';
const PARENT_RELATION_MODAL_TITLE = 'Document relation warning';

export const AchMetadataEditModal = ({ useGetDataListWithParams }: DocumentViewModalProps) => {
  const [apiError, setApiError] = useState<string>();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const document = useAppSelector(documentsSelectors.selectModalDocument);
  const [update] = useUpdateDocumentsMetadataMutation();
  const { documentIsParent, isLoading: isCheckingParent } = useCheckIfDocumentIsParent(
    document?.DocumentId!
  );

  const { filters } = useGetDataListWithParams();
  const { SearchQuery, OriginalLanguage, SearchMethod } = getLastNotEmptyQuery(filters.Queries);
  const keyword = SearchQuery;
  const original = OriginalLanguage;
  const method = SearchMethod;
  const [language, setLanguage] = useLanguageToggleSwitch(
    document?.DocumentId,
    true,
    keyword,
    original
  );
  const { isLoading, startLoading, stopLoading, loadWithTimeout } = useLoadingWithTimeout();

  const handleClose = () => {
    dispatch(closeMetadataEditModal());
    setApiError(undefined);
  };

  const handleSubmit = (data: DocumentMetadata) => {
    setApiError(undefined);
    if (!document) return;
    const changedDocuments = mapToDocumentMetadataUpdateRequestDto([{ ...document, ...data }]);
    startLoading();
    update(changedDocuments)
      .unwrap()
      .then((response) => {
        dispatch(updateStatuses(mapSuccessResponseToUpdateStatuses(response, changedDocuments)));
        if (isAchResponseWithErrors(response)) {
          stopLoading();
          setApiError(mapAchErrorResponseToErrorMessage(response));
        } else {
          loadWithTimeout(() => {
            handleClose();
            enqueueSnackbar(Messages.MetadataUpdateSuccess);
          });
        }
      })
      .catch((response) => {
        stopLoading();
        dispatch(updateStatuses(mapErrorResponseToUpdateStatuses(response, changedDocuments)));
        setApiError(handleApiError(response));
      });
  };

  return (
    <Modal open={!!document?.DocumentId} onClose={handleClose}>
      <div className='metadata-edit-modal metadata-edit-modal-ach'>
        <WarningModal
          open={documentIsParent || isCheckingParent}
          buttonLabel='OK'
          title={PARENT_RELATION_MODAL_TITLE}
          isLoading={isCheckingParent}
        >
          <div>{PARENT_RELATION_WARNING}</div>
        </WarningModal>
        <Grid container>
          <Grid item xs className='document-view'>
            <div className='document-view__top-bar'>
              <LanguageToggleSwitch value={language} onChange={setLanguage} pdf />
            </div>

            <AchDocumentViewer
              documentId={document?.DocumentId}
              language={language}
              keyword={keyword}
              method={method}
              searchOption
              previewMode
            />
          </Grid>

          <Grid item className='form-view'>
            <div className='form-view__top-bar'>
              <MinimizeItem item={document!} onMinimize={handleClose} />
              <ModalClose onClose={handleClose} />
            </div>
            <div className='metadata-edit-modal-header'></div>
            <div className='metadata-edit-modal-metadata'>
              <div className='doc-info-header'>
                <DocumentInfoHeader document={document} />
              </div>

              <MetadataForm
                disabledColumns={MY_FILES_BLOCKED_COLUMNS.FORM}
                document={document}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                apiError={apiError}
                validate={true}
                disableSaveOnMissingData={true}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
