import { RefObject, useCallback, useEffect, useRef } from 'react';
import Mark, { MarkOptions } from 'mark.js';
import { DirectionFlag } from './FileViewer.types';
import { useSearch } from './ViewerToolbar/SearchContext';
import { SearchMethod } from '../../../store/files/documentsAndClauses/list.types';
import viewer from './FileViewer.module.scss';
import { useAppDispatch } from '../../../store/hooks';
import { setHighlightDetails } from '../../../store/ui/ui.slice';

const TAG = 'markjs';
const CURRENT = 'current';
const GROUP_ATTR = 'data-group';
export const markJsStyles = `<style>${TAG} {scroll-margin: ${viewer.scrollOffset}px; background: ${viewer.markBackground};} ${TAG}.${CURRENT} {background: ${viewer.markCurrentBackground}; color: ${viewer.markCurrentColor};} ${TAG} * {background: none !important;}</style>`;

const markJsOptions: MarkOptions = {
  accuracy: 'partially',
  element: TAG,
  className: '',
  ignorePunctuation: ['-', '- '],
  ignoreJoiners: true,
  acrossElements: true,
};

const groupClass = (group: number) => `g-${group.toString()}`;

export const getHTMLElement = (current: HTMLIFrameElement | HTMLDivElement | null) => {
  if (!current) return;

  let element: HTMLElement | undefined;
  if (current instanceof HTMLIFrameElement) {
    element = current.contentDocument?.body;
  }
  if (current instanceof HTMLDivElement) {
    element = current;
  }

  return element;
};

export const markPhrases = (element: HTMLElement, phrase: string, exact: boolean) => {
  const instance = new Mark(element);
  const options: MarkOptions = {
    ...markJsOptions,
    separateWordSearch: !exact,
  };

  instance.unmark(options);
  instance.mark(phrase, options);
};

const isHyphenated = (str: string | null) => {
  const text = str?.trim() ?? '';
  return text[text.length - 1] === '-';
};

export const groupMarks = (element: HTMLElement, searchTerm: string, exact: boolean) => {
  const marks = element.querySelectorAll(TAG);
  let group = 0;

  if (exact) {
    const firstWord = searchTerm.split(' ')[0].toLocaleLowerCase();
    let first = true;

    marks.forEach((el) => {
      if (el.textContent?.trim().toLocaleLowerCase().indexOf(firstWord) === 0) {
        if (!first && !isHyphenated(el.textContent)) group++;
      }
      first = false;

      el.setAttribute(GROUP_ATTR, group.toString());
      el.classList.add(groupClass(group));
    });
  } else {
    marks.forEach((el) => {
      el.setAttribute(GROUP_ATTR, group.toString());
      el.classList.add(groupClass(group));

      if (!isHyphenated(el.textContent)) group++;
    });
  }
};

export const useHighlight = (
  ref: RefObject<HTMLIFrameElement | HTMLDivElement | null>,
  keyword?: string,
  method?: SearchMethod
) => {
  const { initialize, handleSwitch } = useMarkSwitch(ref);
  const { searchTerm } = useSearch();
  const exact = keyword ? method === SearchMethod.EXACT : true;

  const handleHighlight = useCallback(() => {
    const element = getHTMLElement(ref.current);
    if (!element) return;

    markPhrases(element, searchTerm, exact);

    setTimeout(() => {
      if (!element) return;

      groupMarks(element, searchTerm, exact);

      initialize();
    });
  }, [ref, exact, searchTerm, initialize]);

  useEffect(() => {
    handleHighlight();
  }, [handleHighlight, ref]);

  return { handleHighlight, handleSwitch };
};

export const moveToNextMark = (element: HTMLElement, current: number) => {
  const marks = element.querySelectorAll(TAG);
  marks?.forEach((e) => e.classList.remove(CURRENT));

  const newMarks = element.querySelectorAll(`${TAG}.${groupClass(current)}`);
  newMarks?.forEach((e) => e.classList.add(CURRENT));
  const last = newMarks.length - 1;

  if (newMarks[last]) {
    newMarks[last].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }
};

export const getLastMarksGroup = (element: HTMLElement) => {
  const marks = element.querySelectorAll(TAG);
  const last = marks.length - 1;

  if (marks[last]) {
    const group = marks[last].getAttribute(GROUP_ATTR);
    return group ? parseInt(group) : 0;
  }

  return 0;
};

export const calcNext = (current: number, direction: DirectionFlag, last: number) => {
  const next = current + direction;
  return next > last ? 0 : next < 0 ? last : next;
};

export const useMarkSwitch = (ref: RefObject<HTMLIFrameElement | HTMLDivElement | null>) => {
  const dispatch = useAppDispatch();
  const currentRef = useRef(0);
  const lastRef = useRef(0);

  const updateHighlightState = useCallback(() => {
    const totalHighlights = lastRef.current + 1;
    const currentHighlight = currentRef.current + 1;
    dispatch(setHighlightDetails({ totalHighlights, currentHighlight }));
  }, [dispatch]);

  const switchMark = useCallback(() => {
    const element = getHTMLElement(ref.current);
    if (!element) return;

    moveToNextMark(element, currentRef.current);
  }, [ref]);

  const initialize = useCallback(() => {
    const element = getHTMLElement(ref.current);
    if (!element) return;

    lastRef.current = getLastMarksGroup(element);
    currentRef.current = 0;

    updateHighlightState();
    switchMark();
  }, [ref, switchMark, updateHighlightState]);

  const handleSwitch = useCallback(
    (direction: DirectionFlag = 1) => {
      currentRef.current = calcNext(currentRef.current, direction, lastRef.current);

      updateHighlightState();
      switchMark();
    },
    [switchMark, updateHighlightState]
  );

  return { initialize, handleSwitch };
};
