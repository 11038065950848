import React from 'react';
import { DocumentViewerProps } from './DocumentViewer.types';
import { useGetFileQuery } from '../../../store/files/upload/list.service';
import PdfViewer from '../../StaticComponents/FileViewer/PdfViewer';

export const DocumentViewer = ({ documentId = '' }: DocumentViewerProps) => {
  const { data, isFetching, isError } = useGetFileQuery(documentId, {
    skip: !documentId,
  });

  return <PdfViewer loading={isFetching} error={isError} url={data?.url} previewMode />;
};
