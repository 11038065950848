export type EntityMetadata = {
  label: string;
  value: string;
  related?: {
    Country?: string;
    Language?: string;
  };
};

export interface GroupMetadata {
  GroupName: string;
  Classification: string[];
  Entity: string[];
  Mode: string;
  Owner: string;
  Provider: string;
  ShowReported: boolean;
  Type: string;
}

export interface AdminPanelMetadata {
  Entities: EntityMetadata[];
  Groups: GroupMetadata[];
  TagsLanguages: EntityMetadata[];
}

export interface AdminPanelCreatedUser {
  Active: boolean;
  Entity?: string;
  Email: string;
  UserGroups: string[];
  Approver?: string;
}

export enum AdminPanelErrorDescriptions {
  UserNotFound = 'User not found',
  UserGroupNotFound = 'User group not found',
  UserAlreadyExists = 'User already exists',
  OtherError = 'Other error',
}

export interface AdminPanelUser {
  Entity?: string;
  Email: string;
  UserGroups: string[];
  Approver: string;
  Active: boolean;
  UserId: string;
  Name: string;
  FamilyName: string;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface UpdateUserGroups {
  Groups: string[];
  Operation: UsersGroupsOperation;
  UserEmails: string[];
}

export enum UsersGroupsOperation {
  ADD = 'add',
  DELETE = 'delete',
}

export interface UploadMetadataCsv {
  Csv: string;
}
