import { Approver, UserGroup } from './authorization.service.types';
import { intersection, some } from 'lodash';

const GROUP_PREFIX = 'gwr_';
const CLAUSE_PREFIX = 'gwr_clause_';
const WRITE_GROUP = '_editors';
const DRAFT_SUFFIX = '_drafts';
const DOCUMENT_READ_REPORTED_GROUP =
  'GWR_All_Entities_Global_Users_C1-C3_Readers_Reported'.toLowerCase();

const GLOBALS_PREFIX = 'GWR_clause_All_Entities_Global_Users_';
enum UserGroupEditors {
  C1_C2 = 'C1-C2_Editors',
  C3 = 'C3_Editors',
  C4 = 'C4_Editors',
}
const allGlobalEditors = [
  GLOBALS_PREFIX + UserGroupEditors.C1_C2,
  GLOBALS_PREFIX + UserGroupEditors.C3,
  GLOBALS_PREFIX + UserGroupEditors.C4,
];

const ADMIN_PANEL_GROUP = 'ADMIN_PANEL_GWR'.toLowerCase();

const isDraftGroup = (group: string) => group.toLowerCase().endsWith(DRAFT_SUFFIX);

const isGwrDocumentGroup = (group: string) =>
  group.toLowerCase().startsWith(GROUP_PREFIX) && !isGwrClauseGroup(group);

const isGwrDocumentDraftGroup = (group: string) => isGwrDocumentGroup(group) && isDraftGroup(group);

const isGwrDocumentNonDraftGroup = (group: string) =>
  isGwrDocumentGroup(group) && !isDraftGroup(group);

const isGwrClauseGroup = (group: string) => group.toLowerCase().startsWith(CLAUSE_PREFIX);

const isGwrClauseDraftGroup = (group: string) => isGwrClauseGroup(group) && isDraftGroup(group);

const isGwrClauseNonDraftGroup = (group: string) => isGwrClauseGroup(group) && !isDraftGroup(group);

const isGwrEditorsGroup = (group: string) => group.toLowerCase().includes(WRITE_GROUP);

const isGwrDocumentReaderReportedGroup = (group: string) =>
  group.toLowerCase() === DOCUMENT_READ_REPORTED_GROUP;

const isAdminPanelGroup = (group: string) => group.toLowerCase() === ADMIN_PANEL_GROUP;

export const hasUserDocumentReadAccess = (groups?: string[]) =>
  !!groups?.find(isGwrDocumentNonDraftGroup);

export const hasUserDocumentWriteAccess = (groups?: string[]) =>
  !!groups?.find((group) => isGwrDocumentNonDraftGroup(group) && isGwrEditorsGroup(group));

export const hasUserDraftDocumentWriteAccess = (groups?: string[]) =>
  !!groups?.find((group) => isGwrDocumentDraftGroup(group) && isGwrEditorsGroup(group));

export const hasUserClauseReadAccess = (groups?: string[]) =>
  !!groups?.find(isGwrClauseNonDraftGroup);

export const hasUserClauseWriteAccess = (groups?: string[]) =>
  !!groups?.find((group) => isGwrClauseNonDraftGroup(group) && isGwrEditorsGroup(group));

export const hasUserDraftClauseWriteAccess = (groups?: string[]) =>
  !!groups?.find((group) => isGwrClauseDraftGroup(group) && isGwrEditorsGroup(group));

export const hasUserDocumentReadReportedAccess = (groups?: string[]) =>
  !!groups?.find(isGwrDocumentReaderReportedGroup);

export const hasAdminPanelAccess = (groups?: string[]) => !!groups?.find(isAdminPanelGroup);

export const hasReportsTabAccessFn = (groups?: string[]) =>
  allGlobalEditors.some((group) => groups?.includes(group));

export const hasValidateModalAccessFn = (groups?: UserGroup[]) => {
  const approvers = groups?.map(({ Approver }) => Approver) ?? [];
  const required = [Approver.COMPLIANCE, Approver.LEGAL];
  return some(intersection(approvers, required));
};
