import { API_URL, ADMIN_API_URL } from '../config/envs';

export const DOCUMENT_METADATA_ENDPOINT = API_URL + '/document-metadata';
export const DOCUMENT_CHAT_ENDPOINT = API_URL + '/document-chat';

export const UPLOAD_ENDPOINT = API_URL + '/pre-landing/upload';
export const DOCUMENT_DOWNLOAD_ENDPOINT = API_URL + '/pre-landing/download/{id}';
export const LIST_ENDPOINT = API_URL + '/pre-landing';
export const SUBMIT_ENDPOINT = API_URL + '/pre-landing/submit/{id}';
export const DELETE_ENDPOINT = API_URL + '/pre-landing/{id}';
export const DOWNLOAD_METADATA_CSV_ENDPOINT = API_URL + '/pre-landing/csv';
export const UPLOAD_METADATA_CSV_ENDPOINT = API_URL + '/pre-landing/csv';
export const DOWNLOAD_METADATA_CSV_DICT_ENDPOINT = API_URL + '/pre-landing/csv-dictionary';

export const DOCUMENTS_LIST_ENDPOINT = API_URL + '/ach-document/search';
export const DOCUMENTS_UPDATE_ENDPOINT = API_URL + '/ach-document';
export const DOCUMENTS_DELETE_ENDPOINT = API_URL + '/ach-document/{id}';
export const DOCUMENT_FILE_GET_ENDPOINT = API_URL + '/ach-document/file/{id}';
export const DOCUMENT_FILE_REPORT_ENDPOINT = API_URL + '/ach-document/report-item/';
export const DOCUMENT_FILE_REPORT_DELETE_ENDPOINT = API_URL + '/ach-document/report-item/{id}';
export const DOCUMENT_SEARCH_SIMILAR_ENDPOINT =
  API_URL + '/ach-document/search/similar-clauses/{id}';

export const SAVED_SEARCHES_LIST_ENDPOINT = API_URL + '/saved-search';
export const SAVED_SEARCHES_DELETE_ENDPOINT = API_URL + '/saved-search/{id}';

export const CLAUSES_LIST_ENDPOINT = API_URL + '/clause/search';
export const CLAUSES_CREATE_ENDPOINT = API_URL + '/clause';
export const CLAUSES_RECREATE_ENDPOINT = API_URL + '/clause/resubmit';
export const CLAUSES_UPDATE_ENDPOINT = API_URL + '/clause/{id}';
export const CLAUSE_FILE_GET_ENDPOINT = API_URL + '/clause/file/{id}';
export const CLAUSE_FILE_REPORT_ENDPOINT = API_URL + '/clause/report-item/';
export const CLAUSE_APPROVAL_ENDPOINT = API_URL + '/clause/approval/{id}';
export const CLAUSE_FILE_REPORT_DELETE_ENDPOINT = API_URL + '/clause/report-item/{id}';
export const CLAUSE_INHERITED_METADATA_ENDPOINT = API_URL + '/clause/inherited-metadata/{id}';
export const CLAUSE_FILE_TAG_ENDPOINT = API_URL + '/clause/tags/';

export const BOOKMARKS_LIST_ENDPOINT = API_URL + '/bookmark';
export const BOOKMARKS_ITEMS_ENDPOINT = API_URL + '/bookmark/items';
export const BOOKMARKS_DELETE_ENDPOINT = API_URL + '/bookmark/{id}';

export const REPORTS_LIST_ENDPOINT = API_URL + '/reports';
export const REPORT_ENDPOINT = API_URL + '/report';
export const REPORT_ID_ENDPOINT = API_URL + '/report/{id}';
export const GENERATE_REPORT_ENDPOINT = API_URL + '/report/ecs';

export const ADMIN_PANEL_ENDPOINT = ADMIN_API_URL + '/admin-panel';
export const ADMIN_PANEL_USER_ENDPOINT = ADMIN_API_URL + '/admin-panel/user';
export const ADMIN_PANEL_METADATA_ENDPOINT = ADMIN_API_URL + '/admin-panel/metadata';
export const ADMIN_PANEL_USERS_ENDPOINT = ADMIN_API_URL + '/admin-panel/users';
export const ADMIN_PANEL_USER_GROUPS_ENDPOINT = ADMIN_API_URL + '/admin-panel/user/groups';
export const ADMIN_PANEL_USERS_REPORT_ENDPOINT = ADMIN_API_URL + '/admin-panel/users/report';
export const ADMIN_PANEL_AUTHORIZATION_USER_ENDPOINT = ADMIN_API_URL + '/authorization/user';
export const ADMIN_PANEL_TAGS_LIST_ENDPOINT = ADMIN_API_URL + '/admin-panel/tags';
export const ADMIN_PANEL_TAGS_CREATE_ENDPOINT = ADMIN_API_URL + '/admin-panel/tag';
export const ADMIN_PANEL_UPLOAD_METADATA_CSV_ENDPOINT =
  ADMIN_API_URL + '/admin-panel/validity/upload';

export const AUTHORIZATION_USER_ENDPOINT = API_URL + '/authorization/user';

export const COMMENTS_ENDPOINT = API_URL + '/comment';
export const COMMENTS_LIST_ENDPOINT = API_URL + '/comment/{itemType}/{id}';
export const COMMENTS_DELETE_ENDPOINT = API_URL + '/comment/delete/{id}';
